import React from 'react';
import mainImage from './main.png';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';

function toText(node) {
  let tag = document.createElement('div')
  tag.innerHTML = node
  node = tag.innerText
  return node
}
function shortenText(text, startingPoint, maxLength) {
  return text.length > maxLength ?
    text.slice(startingPoint, maxLength) :
    text
}

const classes = {
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
}

export default class App extends React.Component {

  state = {
    posts: []
  }

  componentDidMount() {
    fetch('https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@mickeysden')
      .then((res) => res.json())
      .then((data) => {
        // Fillter the array
        const res = data.items //This is an array with the content. No feed, no info about author etc..
        const posts = res.filter(item => item.categories.length > 0) // That's the main trick* !
        this.setState({ posts })
      })
  }

  render() {
    return (
      <div style={{ flexGrow: 1 }}>

        <CssBaseline />
        <Container maxWidth="lg">
          <div style={{ 
            backgroundImage: 'url('+mainImage+')',
            height: "200px"
          }}
          >
            <h1 style={{ textAlign: "center", fontSize: "48px", color: "white" }}>Atul Shukla</h1>
            <p style={{ textAlign: "center", color: "white" }}>Most of the stuff I have written recently has been on other platforms. Here are quick links to them.</p>
          </div>
          <Grid container spacing={1}>
            <Grid item xs={3}>
              <Card variant="outlined" style={{ maxWidth: 300 }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Topics
              </Typography>
                  <Typography variant="body2" color="textSecondary">agile ,</Typography>
                  {this.state.posts.map(item =>
                    <Typography variant="body2" color="textSecondary">{item.categories.join(" , ")} ,</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
            {this.state.posts.map(item =>
              <Grid item xs={3}>
                <Card variant="outlined" style={{ maxWidth: 300 }}>
                  <CardMedia
                    className={classes.media}
                    image={item.thumbnail}
                  />
                  <CardContent>
                    <Typography variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                      {shortenText(toText(item.content), toText(item.content).indexOf("Unsplash") + 8, 300) + "..."}
                    </Typography>
                    <Typography variant="body2" component="p">
                      Posted: {shortenText(item.pubDate, 0, 10)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button size="small" href={item.link} target="_blank" color="primary">Read Here</Button>
                  </CardActions>
                </Card>
              </Grid>)}
            <Grid item xs={3}>
              <Card variant="outlined" style={{ maxWidth: 300 }}>
                <CardContent>
                  <Typography variant="h5" component="h2">
                    Agile / Lean: Process or Thought-Process
              </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    While agile and lean processes have been around since the 90s, it's only in the last 10 years or so that they have become the must-have buzzwords, so much so, that people and companies use the ...
              </Typography>
                  <Typography variant="body2" component="p">
                    Posted: 2015-07-09
              </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" href="https://www.linkedin.com/pulse/agilelean-process-thought-process-atul-shukla/" target="_blank" color="primary">Read Here</Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}
